import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Icon } from '@/components/common/Icon';
import { Image as ImageComponent } from '@/components/common/image';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalHeading,
  ModalTrigger,
} from '@/components/common/Modal';
import { VisuallyHidden } from '@/components/common/utils';
import {
  PlayButton,
  VideoDetails,
} from '@/components/common/WistiaComponents/Shared';
import { WistiaEmbed } from '@/components/common/WistiaEmbed';

import type { StandardImage, WistiaPopover } from '@/types/shared';

export type WistiaVideoPlayerProps = {
  image: StandardImage;
  wistiaPopover: WistiaPopover;
  modalPopup?: boolean;
};

export const WistiaVideoPlayer = ({
  image,
  wistiaPopover,
}: WistiaVideoPlayerProps) => {
  return (
    <WistiaImagePopupContainer>
      <ThumbnailImage {...image} width={533} height={400} objectFit="cover" />
      <>
        <VideoPopup>
          <Modal>
            <ModalTrigger aria-label="Play video">
              <PlayButtonContainer
                aria-hidden="true"
                className={
                  wistiaPopover.trackingTag?.blockName &&
                  `tracking-${wistiaPopover.trackingTag.blockName}__${wistiaPopover.trackingTag.blockAction}`
                }
              >
                <PlayButton>
                  <Icon icon="Play" size="24px" aria-hidden="true" />
                </PlayButton>
              </PlayButtonContainer>
            </ModalTrigger>
            <ModalContent className="Modal">
              <ModalClose />

              <ModalHeading>
                <VisuallyHidden>{wistiaPopover.videoTitle}</VisuallyHidden>
              </ModalHeading>
              <WistiaEmbed videoId={wistiaPopover.videoId} />
            </ModalContent>
          </Modal>
        </VideoPopup>
        <VideoDetails wistiaPopover={wistiaPopover} />
      </>
    </WistiaImagePopupContainer>
  );
};

const WistiaImagePopupContainer = styled.div`
  position: relative;
  border-radius: var(--radius-l);
  overflow: hidden;

  ${screen.sm} {
    display: block;
  }
`;

const ThumbnailImage = styled(ImageComponent)`
  margin-bottom: -4px;
`;

const VideoPopup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000031;
  border-radius: var(--spacing-xx-small);

  > span {
    width: 100%;
    height: 100%;
  }
`;

const PlayButtonContainer = styled.div`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;

  svg {
    pointer-events: none;

    path {
      stroke: transparent;
      fill: var(--surface-warm-base);
    }
  }
`;
