import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Paragraph } from '@/components/common/MarkUp';

export const VideoDetails = ({ wistiaPopover }) => {
  return (
    <VideoContent>
      {!!wistiaPopover.videoTitle && (
        <VideoText size="label-md">{wistiaPopover.videoTitle}</VideoText>
      )}
      {!!wistiaPopover.videoLength && (
        <VideoLength size="label-md" hasTitle={!!wistiaPopover.videoTitle}>
          {wistiaPopover.videoLength}
        </VideoLength>
      )}
    </VideoContent>
  );
};

const VideoContent = styled.div`
  display: flex;
  position: absolute;
  bottom: var(--spacing-large);
  background-color: var(--background-subtle);
  backdrop-filter: blur(3px);
  padding: var(--spacing-xx-small) var(--spacing-small);
  border-radius: 8px;
  align-items: center;
  left: 20px;
  margin-right: 20px;
  bottom: 20px;
  max-height: 42px;
  z-index: 2;

  p {
    display: inline;
  }
`;

const VideoText = styled(Paragraph)`
  color: var(--text-strong);
  font-weight: var(--font-weight-medium);
`;

const VideoLength = styled(Paragraph)<{ hasTitle: boolean }>`
  color: var(--text-warm-subtle);
  position: relative;
  font-weight: var(--font-weight-regular);

  ${(props) =>
    props.hasTitle &&
    css`
      padding-left: var(--spacing-small);
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        height: 4px;
        width: 4px;
        background-color: var(--text-warm-subtle);
        border-radius: 50%;
      }
    `}
`;
export const Shadow = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  z-index: 2;
  opacity: 16%;
`;

export const PlayButton = styled.div`
  background: var(--surface-primary);
  padding: 20px;
  border-radius: var(--radius-400);
  width: 64px;
  height: 64px;
  border: none;

  &:hover {
    background: var(--surface-inverse-primary);
  }
  &:active {
    transform: scale(0.96);
  }
  :focus {
    outline: 2px solid var(--surface-primary);
    outline-offset: 2px;
  }
`;
